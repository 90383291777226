import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Chart, ChartData} from 'chart.js';
import Annotation from 'chartjs-plugin-annotation';
import {BaseChartDirective} from 'ng2-charts';
import {
  DeviceManagementImplService,
  SensorManagementImplService,
  WorkbookControllerImplService
} from 'src/generated/hydroponics-device-management-api/services';
import {interval, Subject, Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {defaultChartOptions, noDataPlugin} from '../../../../util/chart-settings';
import {
  SensorDataShowInTableEvent,
  SensorDataShowManualMeasurementEvent,
  SensorDataType,
} from '../sensor-data/sensor-data.component';
import {DeviceDto, SensorDataDto} from 'src/generated/hydroponics-device-management-api/models';
import {ExcelService} from '../../../../util/excel.service';
import * as moment from 'moment-timezone';
import {MatDialog} from '@angular/material/dialog';
import {SensorDataDialogComponent} from '../sensor-data/sensor-data-dialog.component';
import {EventComponent} from "../../../../event/event.component";
import {Roles} from "../../../../auth/role/roles";
import {MessageService} from "../../../../service/message.service";
import {SensorboardManualMeasureComponent} from "../sensorboard-manual-measure/sensorboard-manual-measure.component";

const period = 60 * 1000;

@Component({
  selector: 'base-sensor',
  templateUrl: './base-sensor.component.html',
  styleUrls: ['./base-sensor.component.sass']
})
export class BaseSensorComponent implements OnInit, OnDestroy {
  _device: DeviceDto;

  private static readonly DEFAULT_COLUMNS = ['timestamp'];

  public ec = SensorDataType.EC;
  public iodine = SensorDataType.IODINE;
  public na = SensorDataType.NA;
  public orp = SensorDataType.ORP;
  public ph = SensorDataType.PH;
  public rh = SensorDataType.RH;
  public tamb = SensorDataType.TAMB;
  public tliquid = SensorDataType.TLIQUID;
  public e: Roles;

  public rangeDates: Date[] = [];

  public ecChartData: ChartData;
  public iodineChartData: ChartData;
  public naChartData: ChartData;
  public orpChartData: ChartData;
  public phChartData: ChartData;
  public rhChartData: ChartData;
  public tAmbChartData: ChartData;
  public tLiquidChartData: ChartData;

  public lastReading: SensorDataDto;
  public delta: SensorDataDto;
  public visibleColumns = [];
  public sensorData: SensorDataDto[];
  public rangeEndMaxDate = moment();
  public firstSensorDataTimestamp: Date;

  public chartOptions: any = defaultChartOptions;
  private updateSubscription: Subscription;

  private alertSubscription: Subscription;

  constructor(
    public deviceManagementImplService: DeviceManagementImplService,
    public sensorManagementService: SensorManagementImplService,
    public excel: ExcelService,
    public workbookService: WorkbookControllerImplService,
    public translate: TranslateService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public messageService: MessageService,
  ) {
  }
  @Input('chartFilterDateChangeSubjectSensor') chartFilterDateChangeSubjectSensor:Subject<Date[]>;

  @Input() set device(device: DeviceDto) {
    this._device = device;
    this.loadSensorData();
  }

  @ViewChild(BaseChartDirective)
  chart ?: BaseChartDirective;

  get device(): DeviceDto {
    return this._device;
  }

  ngOnInit(): void {
    Chart.register(noDataPlugin);
    Chart.register(Annotation);
    this.loadFirstSensorDataTimestamp();

    if (this.chartFilterDateChangeSubjectSensor){
      this.chartFilterDateChangeSubjectSensor.subscribe(rangeDates => {
        this.rangeDates = rangeDates;
        this.loadSensorData();
      });
    }

    this.updateSubscription = interval(period).subscribe(() => {
      this.loadSensorData();
    });

    // this.alertSubscription = this.loadAlertsNumberService.event$.subscribe(value => {
    //   console.log("base-sensor");
    //   this.loadSensorData();
    // })
  }

  loadFirstSensorDataTimestamp() {
    this.sensorManagementService.getFirstSensorDataTimestamp({
      deviceId: this.device.deviceId
    }).subscribe({
      next: firstSensorDataTimestamp => {
        if (firstSensorDataTimestamp) {
          this.firstSensorDataTimestamp = new Date(firstSensorDataTimestamp);
        }

      }
    });
  }

  loadLastSensorData() {
    this.sensorManagementService.getCurrentSensorData({
      deviceId: this.device.deviceId
    }).subscribe({
      next: sensorData => {
        this.lastReading = sensorData;
      }
    });
  }

  getStartTime() {
    return moment(this.rangeDates[0]).tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSSZ');
  }

  getEndTime() {
    return this.rangeDates[1]
      ? moment(this.rangeDates[1]).tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSSZ')
      : moment.tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSSZ');
  }

  loadSensorData() {
    if (this.rangeDates[0] > this.rangeDates[1]) {
      this.rangeDates[1] = undefined;
    }
    const startTime = this.getStartTime();
    const endTime = this.getEndTime();

    this.sensorManagementService.getSensorData({
      start: startTime,
      end: endTime,
      deviceId: this.device.deviceId
    }).subscribe({
      next: sensorData => {

        this.sensorData = sensorData;

        const ecData: number[] = [];
        const iodideData: number[] = [];
        const naData: number[] = [];
        const orpData: number[] = [];
        const phData: number[] = [];
        const rhData: number[] = [];
        const tambData: number[] = [];
        const tliquidData: number[] = [];
        const timestamps: string[] = [];

        sensorData.forEach(data => {
          if (data.ec !== undefined && data.ec != null) ecData.push(data.ec);
          if (data.iodine !== undefined && data.iodine != null) iodideData.push(data.iodine);
          if (data.na !== undefined && data.na != null) naData.push(data.na);
          if (data.orp !== undefined && data.orp != null) orpData.push(data.orp);
          if (data.ph !== undefined && data.ph != null) phData.push(data.ph);
          if (data.rh !== undefined && data.rh != null) rhData.push(data.rh);
          if (data.tamb !== undefined && data.tamb != null) tambData.push(data.tamb);
          if (data.tliquid !== undefined && data.tliquid != null) tliquidData.push(data.tliquid);
          if (data.timestamp) timestamps.push(data.timestamp);
        });

        this.ecChartData = {
          datasets: [
            {
              data: ecData,
              backgroundColor: 'rgba(148,159,177,0.2)',
              borderColor: 'rgba(148,159,177,1)',
              pointRadius: 0,
              // borderWidth: 2,

            },
          ],
          labels: timestamps,
        };

        this.iodineChartData = {
          datasets: [
            {
              data: iodideData,
              backgroundColor: 'rgb(37, 150, 190,0.2)',
              borderColor: 'rgb(37, 150, 190)',
              pointRadius: 0
            },
          ],
          labels: timestamps,
        };

        this.naChartData = {
          datasets: [
            {
              data: naData,
              backgroundColor: 'rgb(226,135,67,0.2)',
              borderColor: 'rgb(226,135,67)',
              pointRadius: 0
            },
          ],
          labels: timestamps,
        };

        this.orpChartData = {
          datasets: [
            {
              data: orpData,
              backgroundColor: 'rgb(135,62,35,0.2)',
              borderColor: 'rgb(135,62,35)',
              pointRadius: 0
            },
          ],
          labels: timestamps,
        };

        this.phChartData = {
          datasets: [
            {
              data: phData,
              backgroundColor: 'rgb(6,57,112,0.2)',
              borderColor: 'rgb(6,57,112)',
              pointRadius: 0
            },
          ],
          labels: timestamps,
        };

        this.rhChartData = {
          datasets: [
            {
              data: rhData,
              backgroundColor: 'rgb(171,219,227,0.2)',
              borderColor: 'rgb(171,219,227)',
              pointRadius: 0
            },
          ],
          labels: timestamps,
        };

        this.tAmbChartData = {
          datasets: [
            {
              data: tambData,
              backgroundColor: 'rgb(118,181,197,0.2)',
              borderColor: 'rgb(118,181,197)',
              pointRadius: 0
            },
          ],
          labels: timestamps,
        };

        this.tLiquidChartData = {
          datasets: [
            {
              data: tliquidData,
              backgroundColor: 'rgb(30,129,176,0.2)',
              borderColor: 'rgb(30,129,176)',
              pointRadius: 0
            },
          ],
          labels: timestamps,
        };
      },
      error: e => {
        //TODO: kiemelni nyelvi kulcsba, mindenhol átnézni és nyelvesitve megoldani
        this.messageService.error("Error while loading sensor data", true);

      },
      complete: () => {
        this.rangeEndMaxDate = moment();
      },
    });

    this.loadLastSensorData();
  }

  public exportAllData(): void {
    let data = this.sensorData.map(data => {
      return {
        timestamp: data.timestamp,
        deviceId: this.device.deviceId,
        ec: data.ec,
        iodine: data.iodine,
        na: data.na,
        orp: data.orp,
        ph: data.ph,
        rh: data.rh,
        tamb: data.tamb,
        tliquid: data.tliquid,
      };
    });
    this.excel.exportAsExcelFile(data, 'hyroponics-data-' + new Date().toISOString());
  }

  public showInTable(event: SensorDataShowInTableEvent) {
    this.visibleColumns = [...BaseSensorComponent.DEFAULT_COLUMNS, event.dataType + '_raw', event.dataType];
    this.dialog.open(SensorDataDialogComponent, {
      maxWidth: '95vw',
      maxHeight: '95vw',
      width: '95%',
      height: '95%',
      panelClass: 'sensor-data-modal',
      data: {datasource: this.sensorData, visibleColumns: this.visibleColumns},
    });
  }

  public showManualMeasurements(event: SensorDataShowManualMeasurementEvent) {
    this.visibleColumns = [...BaseSensorComponent.DEFAULT_COLUMNS, event.dataType];
    this.sensorManagementService.getManualMeasurementDataHistory1({
        start: this.getStartTime(),
        end: this.getEndTime(),
        deviceId: this.device.deviceId
      }
    ).subscribe(res => {
      //Rendezés
      let labeledData = res.filter(value => value[event.dataType.toString()] != null).sort(function (a, b) {
        return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
      });
      this.dialog.open(SensorDataDialogComponent, {
        maxWidth: '95vw',
        maxHeight: '95vw',
        width: '95%',
        height: '95%',
        panelClass: 'sensor-data-modal',
        data: {datasource: labeledData, visibleColumns: this.visibleColumns},
      });
    });
  }


  public showAllInTable(): void {
    this.visibleColumns = [
      ...BaseSensorComponent.DEFAULT_COLUMNS,
      SensorDataType.EC,
      SensorDataType.IODINE,
      SensorDataType.NA,
      SensorDataType.ORP,
      SensorDataType.PH,
      SensorDataType.RH,
      SensorDataType.TAMB,
      SensorDataType.TLIQUID,
    ];
    this.dialog.open(SensorDataDialogComponent, {
      maxWidth: '95vw',
      maxHeight: '95vw',
      width: '95%',
      height: '95%',
      panelClass: 'sensor-data-modal',

      data: {datasource: this.sensorData, visibleColumns: this.visibleColumns},
    });
  }

  openEvents() {
    this.dialog.open(EventComponent, {data: {deviceId: this.device.deviceId}});
  }

  openManualMeasure() {
    this.dialog.open(SensorboardManualMeasureComponent, {data: {deviceId: this.device.deviceId}});
  }

  ngOnDestroy():void {
    if (this.updateSubscription){
      this.updateSubscription.unsubscribe();
    }

    if (this.chartFilterDateChangeSubjectSensor){
      this.chartFilterDateChangeSubjectSensor.unsubscribe();
    }

    if (this.alertSubscription) {
      this.alertSubscription.unsubscribe();
    }
  }

}
