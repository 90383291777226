import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {interval, Subject, Subscription} from "rxjs";
import {defaultChartOptions, noDataPlugin} from "../../util/chart-settings";
import {
  BaseSensorDataType,
  ImpedanceSensorDataType,
  OperationType,
  SensorDataType
} from "../device/sensorboard/sensor-data/sensor-data.component";
import {Chart, ChartData} from "chart.js";
import Annotation from "chartjs-plugin-annotation";
import {
  SensorManagementImplService
} from "../../../generated/hydroponics-device-management-api/services/sensor-management-impl.service";
import * as moment from "moment-timezone";
import {MessageService} from "../../service/message.service";
import {ChartFilterService} from "../chart-filter/chart-filter.service";
import {ChartDevice} from "../sensor-measure-dashboard/sensor-measure-dashboard.component";
import {
  FavouriteChartManagementControllerImplService
} from "../../../generated/hydroponics-device-management-api/services/favourite-chart-management-controller-impl.service";
import {
  ImpedanceManagementControllerImplService
} from "../../../generated/hydroponics-device-management-api/services/impedance-management-controller-impl.service";
import {
  ImpedanceSensorDataDto
} from "../../../generated/hydroponics-device-management-api/models/impedance-sensor-data-dto";
import {
  DeviceAlertsCountDto
} from "../../../generated/hydroponics-device-management-api/models/device-alerts-count-dto";
import {LoadAlertsAndWorksheetsNumberService} from "../../service/load-alerts-and-worksheets-number.service";
import {
  AlertManagementImplService
} from "../../../generated/hydroponics-device-management-api/services/alert-management-impl.service";
import {
  WorksheetControllerImplService
} from "../../../generated/hydroponics-device-management-api/services/worksheet-controller-impl.service";
import {
  HasRecentAlertsForDeviceResponse
} from "../../../generated/hydroponics-device-management-api/models/has-recent-alerts-for-device-response";

const period = 60 * 1000;

export interface ExtendedChartData {
  data?: { [p: string]: ChartData },
  label?: string,
  settlement?: string,
  locations?: string,
  deviceName?: string,
  alerts: number,
  chartAlerts: DeviceAlertsCountDto,
  worksheets: number
}

export interface RecentAlertsByDevice {
  deviceId?: string;
  alerts?: HasRecentAlertsForDeviceResponse;
}

@Component({
  selector: 'app-sensor-charts',
  templateUrl: './sensor-charts.component.html',
  styleUrls: ['./sensor-charts.component.sass']
})
export class SensorChartsComponent implements OnInit, OnDestroy {

  @Input('chartFilterChangeSubjectSensor') chartFilterChangeSubjectSensor: Subject<any>;
  @Input('chartFilterAttributeChangeSubjectSensor') chartFilterAttributeChangeSubjectSensor: Subject<string[]>;
  @Input('chartFilterFrequenciesChangeSubjectSensor') chartFilterFrequenciesChangeSubjectSensor: Subject<number[]>;
  @Input() isFavOnly: boolean = false;
  @Input() operationTypes: OperationType[];
  @Input() calibrationTypes?: string[];
  @Input() onlyFavourite: boolean;

  private rangeDates: Date[];
  private updateSubscription: Subscription;
  public chartOptions: any = defaultChartOptions;

  /**
   * DeviceId -> attributeName -> ChartData (ec/ph/.. stb chart adat)
   */
  protected deviceData: { [p: string]: ExtendedChartData } = {}
  private chartDevices: ChartDevice[];
  private attributeNames: string[];
  private favouriteCharts: { [p: string]: boolean } = {};
  private locationIds: any[];
  protected frequencies: number[];
  private recentAlerts: RecentAlertsByDevice = {};
  private loadAlertsAndWorksheetsNumberServiceSubscription: Subscription;

  constructor(public sensorManagementService: SensorManagementImplService,
              public messageService: MessageService,
              public chartFilterService: ChartFilterService,
              public impedanceManagerControllerImplService: ImpedanceManagementControllerImplService,
              public favouriteChartManagementControllerImplService: FavouriteChartManagementControllerImplService,
              private loadAlertsAndWorksheetsNumberService: LoadAlertsAndWorksheetsNumberService,
              private readonly alertManagementImplService: AlertManagementImplService,
              private readonly worksheetControllerImplService: WorksheetControllerImplService,
  ) {
  }

  ngOnInit(): void {
    Chart.register(noDataPlugin);
    Chart.register(Annotation);

    if (this.chartFilterChangeSubjectSensor) {
      this.chartFilterChangeSubjectSensor.subscribe(changeParams => {
        this.rangeDates = changeParams.rangeDates;
        this.chartDevices = changeParams.chartDevices;
        this.locationIds = changeParams.locationIds;

        this.deviceData = {};
        if ((this.chartDevices && this.chartDevices.length > 0) || (this.locationIds && this.locationIds.length > 0)) {
          this.loadSensorData();
          this.refreshUpdatePeriod();
        }
      });
    }

    this.initFavouriteCharts();

    if (this.chartFilterAttributeChangeSubjectSensor) {
      this.chartFilterAttributeChangeSubjectSensor.subscribe(attributeNames => {
        this.attributeNames = attributeNames;
      });
    }

    if (this.chartFilterFrequenciesChangeSubjectSensor) {
      this.chartFilterFrequenciesChangeSubjectSensor.subscribe(frequencies => {
        this.frequencies = frequencies;
        this.loadSensorData();
        this.refreshUpdatePeriod();
      });
    }

    this.refreshUpdatePeriod();
    this.chartFilterService.markInitialized();

    this.loadAlertsAndWorksheetsNumberServiceSubscription = this.loadAlertsAndWorksheetsNumberService.event$.subscribe(value => {
      this.loadSensorData()
    });
  }

  private initFavouriteCharts() {
    this.favouriteChartManagementControllerImplService.getFavouritesByUser()
      .subscribe(favouriteChartDtos => {
        this.favouriteCharts = {};
        if (favouriteChartDtos) {
          favouriteChartDtos.forEach(favouriteChartDto => {
            this.favouriteCharts[favouriteChartDto.device.deviceId + '_' + SensorDataType[favouriteChartDto.type as keyof typeof SensorDataType]] = true;
          })
        }
      });
  }

  refreshUpdatePeriod() {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }

    this.updateSubscription = interval(period).subscribe(() => {
      this.loadSensorData();
    });
  }

  /**
   * Szűrési feltételek alapján felszedi a sensor adatokat.
   */
  loadSensorData() {
    if (this.rangeDates[0] > this.rangeDates[1]) {
      this.rangeDates[1] = undefined;
    }
    const startTime = this.getStartTime();
    const endTime = this.getEndTime();
    this.recentAlerts = {};
    if (this.chartDevices && this.chartDevices.length > 0) {
      // Eszközönként szedjük fel a sensor adatokat a korábban szűrés során előálló objektumok alapján
      // Sok eszköz van -> nagy a chartDevices mérete, egymás után szedjük össze eszközönként
      this.chartDevices.forEach((chartDevice, key) => {
        let baseSensorDataTypeList = [];
        for (const key in BaseSensorDataType) {
          baseSensorDataTypeList.push(chartDevice.deviceId + '_' + BaseSensorDataType[key as keyof typeof BaseSensorDataType]);
        }
        if (!this.onlyFavourite || baseSensorDataTypeList.some(chart => chart in this.favouriteCharts)) {
          this.loadSensorDatas(startTime, endTime, chartDevice);
        }

        let impedanceSensorDataTypeList = [];
        for (const key in ImpedanceSensorDataType) {
          impedanceSensorDataTypeList.push(chartDevice.deviceId + '_' + ImpedanceSensorDataType[key as keyof typeof ImpedanceSensorDataType]);
        }


        if (!this.onlyFavourite || impedanceSensorDataTypeList.some(chart => chart in this.favouriteCharts)) {
          this.loadImpedanceSensorData(startTime, endTime, chartDevice);
        }

        this.alertManagementImplService.hasRecentAlertsForDevice({
          deviceId: chartDevice.deviceId,
          fromDate: startTime
        }).subscribe(value => {
          this.recentAlerts[chartDevice.deviceId] = {
            ...(this.recentAlerts[chartDevice.deviceId] || {}),
            ...value
          };
        });

      });
    }
  }

  public loadImpedanceSensorData(startTime: string, endTime: string, chartDevice: ChartDevice) {
    if (this.frequencies) {
      this.impedanceManagerControllerImplService.getImpedanceSensorData({
        start: startTime, end: endTime, deviceId: chartDevice.deviceId, body: this.frequencies
      }).subscribe({
          next: impedanceSensorDataList => {
            this.fillChartData(impedanceSensorDataList, 'impedancePhase', chartDevice, SensorDataType.IMPEDANCEPHASE);
            this.fillChartData(impedanceSensorDataList, 'impedance', chartDevice, SensorDataType.IMPEDANCE);
            this.alertManagementImplService.getAlertsForImpedanceSensorByDevice({deviceId: chartDevice.deviceId}).subscribe(value => {
              if (this.deviceData[chartDevice.deviceId]) {
                if (!this.deviceData[chartDevice.deviceId].chartAlerts) {
                  this.deviceData[chartDevice.deviceId].chartAlerts = {};
                }
                Object.assign(this.deviceData[chartDevice.deviceId].chartAlerts, {
                  impedance: value.impedance,
                  impedancePhase: value.impedance_PHASE,
                });
              }
            });
          },
          error: e => {
            this.messageService.error(e.message, true);
          },
          complete: () => {
            //this.rangeEndMaxDate = moment();
          }
        }
      )
    }
  }

  private fillChartData(impedanceSensorDataList: Array<ImpedanceSensorDataDto>, value: string, chartDevice: ChartDevice, sensorType: SensorDataType) {
    if (impedanceSensorDataList.length != 0) {
      let chartDataByFrequenyMap = new Map<number, any[]>;
      impedanceSensorDataList.forEach(impedanceSensorData => {
          impedanceSensorData.impedanceSensorDataParams.forEach(impedanceSensorDataParam => {
            if (chartDataByFrequenyMap.get(impedanceSensorDataParam.frequency) == null) {
              chartDataByFrequenyMap.set(impedanceSensorDataParam.frequency, []);
            }
            chartDataByFrequenyMap.get(impedanceSensorDataParam.frequency).push({
              x: new Date(impedanceSensorData.timestamp),
              y: impedanceSensorDataParam[value]
            });
          })
        }
      )

      if (!this.deviceData[chartDevice.deviceId]) {
        this.initDeviceData(chartDevice);
      }

      this.deviceData[chartDevice.deviceId].data![sensorType] = {
        datasets: [],
        labels: [],
      };
      chartDataByFrequenyMap.forEach((value, key) => {
        this.deviceData[chartDevice.deviceId].data![sensorType].datasets.push({
          data: value.sort(((a, b) => {
            return <any>new Date(b.x) - <any>new Date(a.x)
          })),
          pointRadius: 0
        })
      })
    }
  }

  private initDeviceData(chartDevice: ChartDevice) {
    this.deviceData[chartDevice.deviceId] = {
      settlement: chartDevice.settlement,
      deviceName: chartDevice.deviceName,
      locations: chartDevice.locations,
      alerts: chartDevice.alerts,
      chartAlerts: chartDevice.chartAlerts,
      worksheets: chartDevice.worksheets,
      data: {}
    };
  }

  getStartTime() {
    return this.rangeDates && this.rangeDates[0]
      ? moment(this.rangeDates[0]).tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSSZ')
      : moment.tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSSZ');
  }

  getEndTime() {
    return this.rangeDates && this.rangeDates[1]
      ? moment(this.rangeDates[1]).tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSSZ')
      : moment.tz('Europe/Budapest').format('yyyy-MM-DDTHH:mm:ss.SSSZ');
  }

  deviceDataKeys(): string[] {
    return Object.keys(this.deviceData);
  }

  chartDataKeys(deviceKey: string) {
    return this.attributeNames.sort((a, b) => {
      const aAlert = this.getAlert(deviceKey, a) ? 1 : 0;
      const bAlert = this.getAlert(deviceKey, b) ? 1 : 0;

      return bAlert - aAlert;
    });
  }

  deviceDataValues(deviceKey: string, chartKey: string): ChartData {
    return this.deviceData[deviceKey].data[chartKey];
  }

  getDeviceData(deviceKey: string) {
    return this.deviceData[deviceKey];
  }

  getAlert(deviceId: string, chartKey: string) {
    if (chartKey && deviceId) {
      return this.recentAlerts?.[deviceId]?.[chartKey];
    }
  }

  isFav(deviceId: string, attributeName: string) {
    let key = deviceId + '_' + attributeName;
    return this.favouriteCharts[key];
  }

  private fillData(chartDevice: ChartDevice, sensorDataType: SensorDataType, data: any, timestamps: string[], bgColor: string, borderColor: string) {
    this.deviceData[chartDevice.deviceId].data![sensorDataType] = {
      datasets: [
        {
          data: data,
          backgroundColor: bgColor,
          borderColor: borderColor,
          pointRadius: 0,
        },
      ],
      labels: timestamps,
    };
  }

  private loadSensorDatas(startTime: string, endTime: string, chartDevice: ChartDevice) {
    // Egy eszközre szedi fel a sensor adatokat
    this.sensorManagementService.getSensorData({
      start: startTime,
      end: endTime,
      deviceId: chartDevice.deviceId
    }).subscribe({
      next: sensorData => {
        const ecData: number[] = [];
        const iodineData: number[] = [];
        const naData: number[] = [];
        const orpData: number[] = [];
        const phData: number[] = [];
        const rhData: number[] = [];
        const tambData: number[] = [];
        const tliquidData: number[] = [];
        const timestamps: string[] = [];

        sensorData.forEach(data => {
          if (data.ec !== undefined && data.ec != null) ecData.push(data.ec);
          if (data.iodine !== undefined && data.iodine != null) iodineData.push(data.iodine);
          if (data.na !== undefined && data.na != null) naData.push(data.na);
          if (data.orp !== undefined && data.orp != null) orpData.push(data.orp);
          if (data.ph !== undefined && data.ph != null) phData.push(data.ph);
          if (data.rh !== undefined && data.rh != null) rhData.push(data.rh);
          if (data.tamb !== undefined && data.tamb != null) tambData.push(data.tamb);
          if (data.tliquid !== undefined && data.tliquid != null) tliquidData.push(data.tliquid);
          if (data.timestamp) timestamps.push(data.timestamp);
        });

        if (!this.deviceData[chartDevice.deviceId]) {
          this.initDeviceData(chartDevice);
        }

        this.fillData(chartDevice, SensorDataType.EC, ecData, timestamps, 'rgba(148,159,177,0.2)', 'rgba(148,159,177,1)');
        this.fillData(chartDevice, SensorDataType.IODINE, iodineData, timestamps, 'rgb(37, 150, 190,0.2)', 'rgb(37, 150, 190)');
        this.fillData(chartDevice, SensorDataType.NA, naData, timestamps, 'rgb(226,135,67,0.2)', 'rgb(226,135,67)');
        this.fillData(chartDevice, SensorDataType.ORP, orpData, timestamps, 'rgb(135,62,35,0.2)', 'rgb(135,62,35)');
        this.fillData(chartDevice, SensorDataType.PH, phData, timestamps, 'rgb(6,57,112,0.2)', 'rgb(6,57,112)');
        this.fillData(chartDevice, SensorDataType.RH, rhData, timestamps, 'rgb(171,219,227,0.2)', 'rgb(171,219,227)');
        this.fillData(chartDevice, SensorDataType.TAMB, tambData, timestamps, 'rgb(118,181,197,0.2)', 'rgb(118,181,197)');
        this.fillData(chartDevice, SensorDataType.TLIQUID, tliquidData, timestamps, 'rgb(30,129,176,0.2)', 'rgb(30,129,176)');

        this.alertManagementImplService.getAlertsForNaSensorByDevice({deviceId: chartDevice.deviceId}).subscribe(value => {
          if (!this.deviceData[chartDevice.deviceId].chartAlerts) {
            this.deviceData[chartDevice.deviceId].chartAlerts = {};
          }
          Object.assign(this.deviceData[chartDevice.deviceId].chartAlerts, {
            ec: value.ec,
            orp: value.orp,
            na: value.na,
            ph: value.ph,
            rh: value.rh,
            iodine: value.iodine,
            tamb: value.tamb,
            tliquid: value.tliquid
          });
        });

        this.worksheetControllerImplService.getCountWorksheets({deviceId: chartDevice.deviceId}).subscribe(value => {
          this.deviceData[chartDevice.deviceId].worksheets = value;
        });
      },
      error: e => {
        this.messageService.error(e.message, true);
      },
      complete: () => {
        // TODO ha kell csak itt a chart filternek kellene állítani ami szinte lehetetlen vagy nem?
        //this.rangeEndMaxDate = moment();
      },
    });
  }

  ngOnDestroy(): void {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }

    if (this.chartFilterChangeSubjectSensor) {
      this.chartFilterChangeSubjectSensor.unsubscribe();
    }

    if (this.chartFilterAttributeChangeSubjectSensor) {
      this.chartFilterAttributeChangeSubjectSensor.unsubscribe();
    }

    if (this.chartFilterFrequenciesChangeSubjectSensor) {
      this.chartFilterFrequenciesChangeSubjectSensor.unsubscribe();
    }

    if (this.loadAlertsAndWorksheetsNumberServiceSubscription) {
      this.loadAlertsAndWorksheetsNumberServiceSubscription.unsubscribe();
    }
  }

  toggleIsFavoriteNotification(deviceKey: string, chartKey: string) {
    let key = deviceKey + '_' + chartKey;
    delete this.favouriteCharts[key];
  }
}
