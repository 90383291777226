<ng-container *ngIf="workElement.workElementTypeEnum == WorkTypeEnum.EVENT; else worksheetTemplate">
    <div class="flex-item card hover:bg-red-100 hover:text-black-alpha-90 hovered-element" >
      <div class="mb-1">
        <span
          class="mr-2 font-bold">{{"" != workElement.createUser ? workElement.createUser : ('hydroponics.user.unknown' | translate)}}</span>
        <span class="work-type-EVENT">{{'hydroponics.worksheet.event' | translate}}</span>
        <div
          class="float-right">{{workElement.creationDate | date: dateService.longdateFormatPattern.toString() }}</div>
      </div>
      <div class="mb-3 font-italic">
        <span *ngIf="locationName">{{ locationName }}</span>
        <span *ngIf="workElement.device"
              class="">{{ " - " + workElement.device.name }}</span>
      </div>
      <div class="break-comment">
        {{workElement.description}}
      </div>
    </div>
  </ng-container>
  <ng-template #worksheetTemplate>
    <ng-container *ngIf="!isExtended">
          <div class="flex-item card hover:bg-red-100 hover:text-black-alpha-90"
               [ngClass]="{'bg-red-100 text-black-alpha-90': this.workElement.status =='OPEN'}">
            <div class="mb-1">
              <span
                class="mr-2 font-bold">{{"" != workElement.createUser ? workElement.createUser : ('hydroponics.user.unknown' | translate)}}</span>
              <span class="work-type-WORKSHEET">{{'hydroponics.worksheet.worksheet' | translate}}</span>
              <div
                class="float-right">{{workElement.creationDate | date: dateService.longdateFormatPattern.toString() }}</div>
            </div>
            <div *ngIf="workElement.status == 'CLOSED'" class="font-italic">
              {{'hydroponics.worksheet.closingDate' | translate}}: {{workElement.closeDate | date: dateService.longdateFormatPattern.toString()}}
            </div>
            <div class="mb-3 font-italic">
              <span *ngIf="locationName">{{ locationName }}</span>
              <span *ngIf="workElement.device"
                    class="">{{ " - " + workElement.device.name }}</span>
            </div>
            <div class="break-comment">
              {{workElement.description}}
            </div>
          </div>
      </ng-container>
    <ng-container *ngIf="isExtended">
        <div class="flex-item card" [ngClass]="{'bg-red-100 text-black-alpha-90': this.workElement.status =='OPEN'}">
          <div class="mb-1">
            <span
              class="mr-2 font-bold">{{"" != workElement.createUser ? workElement.createUser : ('hydroponics.user.unknown' | translate)}}</span>
            <span class="work-type-WORKSHEET">{{'hydroponics.worksheet.worksheet' | translate}}</span>
            <div
              class="float-right">{{workElement.creationDate | date: dateService.longdateFormatPattern.toString() }}</div>
          </div>
          <div *ngIf="workElement.status == 'CLOSED'" class="mb-1 font-italic">
            {{'hydroponics.worksheet.closingDate' | translate}}: {{workElement.closeDate | date: dateService.longdateFormatPattern.toString()}}
          </div>
          <div class="mb-3 font-italic">
            <span *ngIf="locationName">{{ locationName }}</span>
            <span *ngIf="workElement.device"
                  class="">{{ " - " + workElement.device.name }}</span>
          </div>
          <div class="mb-3 break-comment">
            {{workElement.description}}
          </div>
          <div class="mb-3">
            <p-button *ngIf="workElement.status == 'OPEN' && !inProcess"
                      icon="pi pi-pencil"
                      label="{{'hydroponics.worksheet.button.work' | translate}}"
                      (onClick)="worksheetInProcess()"></p-button>
          </div>
          <div *ngIf="inProcess" class="mb-3">
            <div class="mb-3">
          <textarea class="p-inputtextarea p-inputtext comment-textarea"
                    rows="5"
                    placeholder="{{'hydroponics.worksheet.commentPlaceholder' | translate}}"
                    pInputTextarea
                    [(ngModel)]="comment"
                    maxlength="1500"
          >
          </textarea>
            </div>
            <div class="mb-3">
              <p-checkbox
                [binary]="true"
                [(ngModel)]="closeWorksheetOnSave"
                label="{{'hydroponics.worksheet.closeWorksheet' | translate}}"></p-checkbox>
            </div>
            <div>
              <p-button class="mr-2" icon="pi pi-plus" label="{{'hydroponics.worksheet.saveNewComment' | translate}}" (onClick)="saveComment()" [disabled]="canSaveComment()"></p-button>
              <p-button icon="pi pi-times" label="{{'hydroponics.button.cancel' | translate}}" (onClick)="inProcess = false"></p-button>
            </div>
          </div>
          <div *ngIf="comments && comments.length > 0">
            <p-accordion (onOpen)="getComments()">
              <p-accordionTab [header]="'Kommentek'">
                <div *ngFor="let commentDto of comments">
                  <app-worksheet-comment
                    [comment]="commentDto">
                  </app-worksheet-comment>
                </div>
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>
      </ng-container>
  </ng-template>
