import {Component, EventEmitter, Input, OnDestroy, OnInit, Output,} from '@angular/core';
import {ChartData, ChartOptions} from 'chart.js';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from "@angular/material/dialog";
import {CalibrationComponent} from "../calibration/calibration.component";
import {MenuItem} from "primeng/api";
import {ContextMenu} from "primeng/contextmenu";
import {ProfileType} from "../../../plant-profile-attribute-manager/plant-profile-attribute-manager.component";
import {Subject} from "rxjs";
import {
  PlantProfileManagerControllerImplService
} from "../../../../../generated/hydroponics-device-management-api/services/plant-profile-manager-controller-impl.service";
import {PlantProfileDto} from "../../../../../generated/hydroponics-device-management-api/models/plant-profile-dto";
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {SensorboardManualMeasureComponent} from "../sensorboard-manual-measure/sensorboard-manual-measure.component";
import {
  FavouriteChartManagementControllerImplService
} from "../../../../../generated/hydroponics-device-management-api/services/favourite-chart-management-controller-impl.service";
import {WorkbookExportComponent} from "../workbook-export/workbook-export.component";
import {ExtendedChartData} from "../../../sensor-charts/sensor-charts.component";
import {Router} from "@angular/router";

export enum SensorDataType {
  EC = 'ec',
  IODINE = 'iodine',
  NA = 'na',
  ORP = 'orp',
  PH = 'ph',
  RH = 'rh',
  TAMB = 'tamb',
  TLIQUID = 'tliquid',
  IMPEDANCE = "impedance",
  IMPEDANCEPHASE = "impedancePhase",
}

export enum BaseSensorDataType {
  EC = 'ec',
  IODINE = 'iodine',
  NA = 'na',
  ORP = 'orp',
  PH = 'ph',
  RH = 'rh',
  TAMB = 'tamb',
  TLIQUID = 'tliquid'
}

export enum ImpedanceSensorDataType {
  IMPEDANCE = "impedance",
  IMPEDANCE_PHASE = "impedancePhase"
}

export interface SensorDataExportEvent {
  dataType: SensorDataType;
}

export interface SensorDataShowInTableEvent {
  dataType: String;
}

export interface SensorDataShowManualMeasurementEvent {
  dataType: String;
}

export enum OperationType{
  M_MEASURE,
  CALIBRATION,
  OPTIMAL_ATTRIBUTE,
  DOWNLOAD_AUTO,
  DOWNLOAD_MANUAL
}

@Component({
  selector: 'app-sensor-data',
  templateUrl: './sensor-data.component.html',
  styleUrls: ['./sensor-data.component.sass'],
  providers: [DialogService]
})
export class SensorDataComponent implements OnInit, OnDestroy {
  @Input() dataType: string;
  @Input() option: ChartOptions;
  @Input() data: ChartData;
  @Input() deviceId: string;
  @Input() favourite: boolean;
  @Input() frequencies: number[];
  @Input() deviceData: ExtendedChartData;
  @Input() operationTypes: OperationType[];
  @Input() calibrationTypes?: string[];
  @Input() isAlerted: boolean;
  @Output() toggleIsFavoriteNotification = new EventEmitter<void>();

  public lastValue: number;
  public title: string;
  public establishment: string;
  protected readonly ProfileType = ProfileType;
  protected dialogVisible: boolean;
  protected loadPlantProfileSubject:Subject<void> = new Subject();
  protected contextMenuItems: MenuItem[];
  protected plantProfile: PlantProfileDto;
  protected ref: DynamicDialogRef | undefined;
  protected alertDialogVisible: boolean = false;

  constructor(private translate: TranslateService, public dialog: MatDialog,
              private plantProfileService: PlantProfileManagerControllerImplService,
              public dialogService: DialogService,
              public favouriteChartManagementControllerImplService: FavouriteChartManagementControllerImplService,
              private router: Router,
  ) {
  }

  showManualMeasure() {
    let type = '';
    if (this.dataType == SensorDataType.IMPEDANCE || this.dataType == SensorDataType.IMPEDANCEPHASE) {
      type = 'IMPEDANCE'
    } else {
      type = 'BASE'
    }
    this.ref = this.dialogService.open(SensorboardManualMeasureComponent, {data: {deviceId: this.deviceId, measureType: type}});
  }

  showContextMenu(contextMenu: ContextMenu, event: MouseEvent) {
    this.contextMenuItems = [
      {label: this.translate.instant('hydroponics.charts.manualMeasure'), icon: 'pi pi-pencil', visible: this.isVisibleOperation(OperationType.M_MEASURE),command: (event) => this.showManualMeasure()},
      {label: this.translate.instant('hydroponics.charts.optimalAttributes'), icon: 'pi pi-eye', visible: this.isVisibleOperation(OperationType.OPTIMAL_ATTRIBUTE),command: (event) => this.loadPlantProfile()},
      {label: this.translate.instant('hydroponics.charts.downloadAutoMeasure'), icon: 'pi pi-save', visible: this.isVisibleOperation(OperationType.DOWNLOAD_AUTO),command: (event) => this.exportDialog('auto')},
      {label: this.translate.instant('hydroponics.charts.downloadManualMeasure'), icon: 'pi pi-save', visible: this.isVisibleOperation(OperationType.DOWNLOAD_MANUAL), command: (event) => this.exportDialog('manual')},
      {label: this.translate.instant('hydroponics.charts.calibration'), icon: 'pi pi-sliders-h', visible: this.isVisibleOperation(OperationType.CALIBRATION), command: (event) => this.calibration()},
    ];

    contextMenu.show(event);
    event.stopPropagation();
  }

  isVisibleOperation(operationType: OperationType){
    if (!this.operationTypes.includes(operationType)){
      return false;
    }

    if (operationType == OperationType.CALIBRATION){
      return this.calibrationTypes.includes(this.dataType.toUpperCase());
    }

    return true;
  }

  ngOnInit(): void {
    this.dialogVisible = false;
    this.translate
      .get('hydroponics.common.measurement.' + this.dataType)
      .subscribe((message: any) => (this.title = message));
  }

  toggleChartFavourite(){
    this.favouriteChartManagementControllerImplService.toggleChartFavouriteToUser({
      deviceId: this.deviceId,
      type: this.dataType
    }).subscribe(() => {
      this.favourite = !this.favourite
      this.ngOnInit();
      this.toggleIsFavoriteNotification.emit();
    });
  }

  exportDialog(type:string): void {
    this.ref = this.dialogService.open(WorkbookExportComponent, {
      data:  {
        dataType: this.dataType,
        deviceId: this.deviceId,
        frequencies: this.frequencies,
        measureType: type
      }
    });
  }

  private loadPlantProfile() {
    this.plantProfileService.getPlantProfileByDevice({deviceId: this.deviceId}).subscribe(profile => {
      this.plantProfile = profile
      this.loadPlantProfileSubject.next();
      this.dialogVisible = true;
    });
  }

  private calibration() {
    this.ref = this.dialogService.open(CalibrationComponent, {data: {dataType: this.title, deviceId: this.deviceId}});
  }

  close() {
    if (this.ref){
      this.ref.close();
    }
  }

  ngOnDestroy(): void {
  }

  showAlertDialog() {
    this.alertDialogVisible = true;
  }

  tableClosed() {
    this.alertDialogVisible = false;
  }

  navigateWithParams() {
    this.router.navigate(['/worksheet', 'DEVICE', this.deviceId]);
  }
}
