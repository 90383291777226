import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private keycloak: KeycloakService,
    private router: Router
  ) {
    this.initializeTokenRefresh();
  }

  private initializeTokenRefresh() {
    // Session figyelése
    this.keycloak.getKeycloakInstance().onTokenExpired = () => {
      console.log('Token lejárt, újrabejelentkezés szükséges');

      // Mentsük el az aktuális útvonalat
      const currentUrl = this.router.url;
      localStorage.setItem('redirectUrl', currentUrl);

      // Irányítsuk a felhasználót a bejelentkező oldalra
      this.keycloak.login({
        redirectUri: window.location.origin + '/auth/callback'
      });
    };
  }

  // Ezt a metódust a sikeres bejelentkezés után hívjuk
  handleSuccessfulLogin() {
    const redirectUrl = localStorage.getItem('redirectUrl') || '/';
    localStorage.removeItem('redirectUrl');
    this.router.navigateByUrl(redirectUrl);
  }
}
