<div class="card" [ngStyle]="isAlerted ? { 'border': '2px solid red' } : {}">
  <div class="chart-container">
    <h4>{{deviceData.settlement}}</h4>
    <h6 class="m-1">{{deviceData.locations}}</h6>
    <h6 class="m-1">{{deviceData.deviceName}}</h6>
    <h6 class="m-1 mb-3">{{ title }} {{ lastValue | number: '1.0-1' }}</h6>

    <div class="chart-actions flex align-items-center">
      <div (click)="showContextMenu(contextMenu, $event);" class="context-menu-button">
        <span class="pi pi-pencil"></span>
      </div>
      <div class="favourite-button" (click)="toggleChartFavourite()">
        <span class="pi" [ngClass]="{'pi-heart': !favourite, 'pi-heart-fill': favourite}"></span>
      </div>
    </div>
    <div *ngIf="deviceData.chartAlerts?.[dataType] && deviceData.chartAlerts[dataType] > 0"
         class="chart-errors flex align-items-center">
      <span>{{deviceData.chartAlerts[dataType]}}</span>
      <div>
        <i class="pi pi-exclamation-triangle cursor-pointer ml-2" (click)="showAlertDialog()"></i>
      </div>
    </div>
    <div *ngIf="deviceData.worksheets && deviceData.worksheets > 0" class="chart-worksheets flex align-items-center">
      <span>{{deviceData.worksheets}}</span>
      <div>
        <i class="pi pi-briefcase ml-2 asd" (click)="navigateWithParams()"></i>
      </div>
    </div>

    <p-chart
      [data]="data"
      [options]="option"
      type="line">
    </p-chart>
  </div>

  <p-dialog modal="true" [(visible)]="dialogVisible">
    <div *ngIf="deviceId">
      <app-plant-profile-attribute-manager
        [title] = "'hydroponics.location.typeOfPlantQuestion' | translate"
        [isViewOnly] = "true"
        [plantProfileEditable] = "false"
        [defaultProfile] = true
        [id] = deviceId
        [type] = ProfileType.DEVICE
        [selectedProfile] = plantProfile
        [loadPlantProfileSubject]="loadPlantProfileSubject">
      </app-plant-profile-attribute-manager>
    </div>
  </p-dialog>
  <p-contextMenu #contextMenu [model]="contextMenuItems" class="context-manu"></p-contextMenu>
</div>

<app-alert-table *ngIf="this.alertDialogVisible"
  [visible]=this.alertDialogVisible
  [deviceId]="this.deviceId"
  [dataType]="this.dataType"
  (closeTable)="tableClosed()">
</app-alert-table>
