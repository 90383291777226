import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {finalize, Observable, Subject, takeUntil, tap, timer} from "rxjs";
import {LoadingSpinnerService} from "./loading-spinner.service";
import {KeycloakService} from "keycloak-angular";

const API_SPINNER_SHOW_DELAY = 3500;

@Injectable({
  providedIn: 'root'
})
export class ApiCallInterceptor implements HttpInterceptor {

  constructor(private loadingSpinner: LoadingSpinnerService,
              private readonly keycloak: KeycloakService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const stopSpinner$ = new Subject<void>(); // Esemény, ami leállítja a késleltetett megjelenítést

    timer(API_SPINNER_SHOW_DELAY).pipe(takeUntil(stopSpinner$)).subscribe(() => {
      this.loadingSpinner.show(); // Csak 300 ms után jelenik meg, ha addig nem ért véget az API hívás
    });

    return next.handle(req).pipe(
      finalize(() => {
        stopSpinner$.next(); // Megállítjuk a késleltetett spinner hívást
        stopSpinner$.complete();
        this.loadingSpinner.hide();
      }),
      tap(
        () => {
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            console.log("(err instanceof HttpErrorResponse):" + err.status);
            if (err.status !== 401) {
              return;
            }
            console.log("this.keycloak.login();");
            this.keycloak.login();
          }
        }
      )
    );
  }
}
