import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from "rxjs";
import {NutritionDispenserManagementImplService} from "../../../../generated/hydroponics-device-management-api/services/nutrition-dispenser-management-impl.service";
import {NutritionDispenserDeviceDto} from "../../../../generated/hydroponics-device-management-api/models/nutrition-dispenser-device-dto";
import {DashboardService} from "../../../dashboard/dashboard.service";

@Injectable({
  providedIn: 'root'
})
export class NutritionDispenserService {

  private nutritionDeviceCurrentParameters = new ReplaySubject<NutritionDispenserDeviceDto>(null);

  getNutritionDeviceCurrentParameters(): Observable<NutritionDispenserDeviceDto> {
    return this.nutritionDeviceCurrentParameters.asObservable();
  }

  constructor(private nutritionDispenserManagementImplService: NutritionDispenserManagementImplService,
              private dashboardService: DashboardService) {
    //this.initNutritionDeviceCurrentParameters();
  }

  initNutritionDeviceCurrentParameters(deviceId) {
    const nutritionDispenserDeviceDtoObservable = this.nutritionDispenserManagementImplService.findNutritionDispenser({deviceId: deviceId});
    nutritionDispenserDeviceDtoObservable.subscribe((res) => {
        this.nutritionDeviceCurrentParameters.next(res);
      }
    );
  }

}
