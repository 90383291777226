import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {DeviceDto} from "../../../../generated/hydroponics-device-management-api/models/device-dto";
import {
  NutritionDispenserManagementImplService
} from "../../../../generated/hydroponics-device-management-api/services/nutrition-dispenser-management-impl.service";
import {DateService} from "../../../util/date.service";
import {MatDialog} from "@angular/material/dialog";
import {
  DeviceOperationControllerImplService
} from "../../../../generated/hydroponics-device-management-api/services/device-operation-controller-impl.service";
import {
  DeviceTypeOperationDto
} from "../../../../generated/hydroponics-device-management-api/models/device-type-operation-dto";
import {FormControl} from "@angular/forms";
import {
  OperateOnDeviceRequest
} from "../../../../generated/hydroponics-device-management-api/models/operate-on-device-request";
import {
  DeviceOperationParamDto
} from "../../../../generated/hydroponics-device-management-api/models/device-operation-param-dto";
import {
  NutritionDispenserDispenseHistoryDto
} from "../../../../generated/hydroponics-device-management-api/models/nutrition-dispenser-dispense-history-dto";
import {interval, Subscription, take, takeUntil} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {NutritionDispenserService} from "../nutrition-dispenser/nutrition-dispenser.service";
import {DeviceOperationDto} from "../../../../generated/hydroponics-device-management-api/models/device-operation-dto";
import {
  DeviceTypeOperationParamDto
} from "../../../../generated/hydroponics-device-management-api/models/device-type-operation-param-dto";
import {DeviceOperationUtilService} from "../../../util/deviceoperation/device-operation-util.service";

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-device-operations',
  templateUrl: './device-operations.component.html',
  styleUrls: ['./device-operations.component.sass']
})
export class DeviceOperationsComponent implements OnInit {

  //Device operation indítás után ennyi másodpercenként frissítjük a history-t
  private readonly DEVICE_OPERATON_HISTORY_REFRESH_INTERVAL = 3;
  //Max ennyi ideig várunk a device operatoin indítása után a státusz változásra
  private readonly DEVICE_OPERATON_MAX_REFRESH_INTERVAL = 300;

  _device: DeviceDto;
  @Input() set device(device: DeviceDto) {
    this._device = device;
    this.refreshData();
  }

  get device(): DeviceDto {
    return this._device;
  }

  operationStartValidationError;

  constructor(private translate: TranslateService,
              private nutritionDispenserManagementImplService: NutritionDispenserManagementImplService,
              public dateService: DateService, public dialog: MatDialog,
              private deviceOperationControllerImplService: DeviceOperationControllerImplService,
              private nutritionDispenserService: NutritionDispenserService,
              private deviceOperationUtilService: DeviceOperationUtilService,
              private cd: ChangeDetectorRef) {
  }

  private updateNutritionDispenseHistory: Subscription;

  ngOnInit(): void {
    this.refreshData();
  }

  private refreshData() {
    this.currentPage = 0;
    this.first = 0;
    this.getDeviceTypeOperations();
    this.getNutritionDispenserDeviceHistoryWithDeviceOperation();
  }

  isLoading = false;
  totalRows = 0;
  pageSize = 5;
  first = 0;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  displayedColumns: string[] = ['operations_start_date', 'quantity', 'reverse', 'response_date', 'result', 'dispensed_quantity'];
  dataSource: NutritionDispenserDispenseHistoryDto[] = []
  isValidForm: boolean = false;

  showDeviceOperationParamsForm = false;

  public frequencies = new FormControl('');

  operateOnDeviceRequest: OperateOnDeviceRequest;
  deviceOperationParamDto: Array<{ deviceTypeOperationParam: DeviceTypeOperationParamDto, actualParam: DeviceOperationParamDto }> = [];
  deviceTypeOperationDtos: Array<DeviceTypeOperationDto> = [];
  pickedDeviceTypeOperationDto: DeviceTypeOperationDto = {};

  pageChanged(event: PageEvent) {
    this.pageSize = event.rows;
    this.first = event.first;
    this.currentPage = event.first / event.rows;
    this.getNutritionDispenserDeviceHistoryWithDeviceOperation();
  }

  getDeviceTypeOperations() {
    this.deviceOperationControllerImplService.getDeviceTypeOperations({deviceId: this.device.deviceId}).subscribe(value => {
      this.deviceTypeOperationDtos = value.deviceTypeOperationDTO;
      this.pickedDeviceTypeOperationDto = value.deviceTypeOperationDTO[0];
      if (this.pickedDeviceTypeOperationDto.deviceTypeOperationParams) {
        this.deviceOperationParamDto = this.pickedDeviceTypeOperationDto.deviceTypeOperationParams.map((p) => {
          return {
            deviceTypeOperationParam: p,
            actualParam: {
              name: p.name,
            }
          }
        });
      }
    });
  }

  cancelOperation() {
    this.operationStartValidationError = null;
    this.showDeviceOperationParamsForm = false;
    this.operateOnDeviceRequest.deviceOperationParams = null;
  }

  startOperation() {
    this.operationStartValidationError = null;
    this.operateOnDeviceRequest = {};
    this.operateOnDeviceRequest.operationCode = this.pickedDeviceTypeOperationDto.code;
    this.operateOnDeviceRequest.deviceOperationParams = this.deviceOperationParamDto.map(p => p.actualParam);
    this.nutritionDispenserService.initNutritionDeviceCurrentParameters(this._device.deviceId);
    this.nutritionDispenserService.getNutritionDeviceCurrentParameters().pipe(take(1)).subscribe(value => {
      let currentVolume = value.quantity;
      //Nincs elég folyadék?
      const quantityParam = this.operateOnDeviceRequest.deviceOperationParams.filter(p => p.name == 'quantity');

      //Ha nincs elég folyadék, akkor egyelőre nem adunk hiba üzenetet, emiatt a következő sorok ki vannak kommentezve (HF-282)
      // if (quantityParam.length > 0 && (currentVolume < quantityParam[0].numberValue)) {
      //   this.translate
      //     .get('hydroponics.nutritionDispenser.error.notEnoughNutrition')
      //     .subscribe((message: any) => (this.operationStartValidationError = message));
      //   return;
      // }


      this.showDeviceOperationParamsForm = false;

      this.deviceOperationControllerImplService.operateOnDevice({
        deviceId: this.device.deviceId,
        body: this.operateOnDeviceRequest
      }).subscribe((res) => {
        this.operateOnDeviceRequest = {};
        this.getNutritionDispenserDeviceHistoryWithDeviceOperation();
        if (this.updateNutritionDispenseHistory) {
          this.updateNutritionDispenseHistory.unsubscribe();
        }
        this.updateNutritionDispenseHistory = interval(this.DEVICE_OPERATON_HISTORY_REFRESH_INTERVAL * 1000).pipe(takeUntil(interval(this.DEVICE_OPERATON_MAX_REFRESH_INTERVAL * 1000))).subscribe(() => {

          const lastDeviceOperationStatus = this.dataSource.filter(p => p.deviceOperationId == res.deviceOperationId)[0]?.deviceOperation?.status;
          if (lastDeviceOperationStatus == "CONFIRMED"
            || lastDeviceOperationStatus == "START_FAILED"
            || lastDeviceOperationStatus == "COMMAND_SEND_ERROR"
            || lastDeviceOperationStatus == "CONFIRMATION_ERROR") {
            this.updateNutritionDispenseHistory.unsubscribe();
          } else {
            this.getNutritionDispenserDeviceHistoryWithDeviceOperation();
          }

        });
      }, finalize => this.nutritionDispenserService.initNutritionDeviceCurrentParameters(this._device.deviceId));

    });
    this.isValidForm = false;
  }

  isDeviceOperationOnError(deviceOperation: DeviceOperationDto): boolean {
    switch (deviceOperation.status) {
      case "COMMAND_SEND_ERROR":
      case "CONFIRMATION_ERROR":
      case "START_FAILED":
        return true;
      default:
        return false;
    }
  }

  getNutritionDispenserDeviceHistoryWithDeviceOperation() {
    this.nutritionDispenserManagementImplService.getNutritionDispenserDeviceHistoryWithDeviceOperation({
      deviceId: this.device.deviceId,
      pageable: {page: this.currentPage, size: this.pageSize, sort: ["operationDate", "desc"]}
    }).subscribe(value => {
      this.totalRows = value.totalElements
      this.dataSource = value.content;
    });
  }

  getDeviceOperationParamValue(paramName: string, deviceOperation: DeviceOperationDto) {
    let param = deviceOperation.deviceOperationParams.filter(p => p.name == paramName)[0];
    if (param) {
      switch (deviceOperation.deviceTypeOperation.deviceTypeOperationParams.filter(p => p.name == paramName)[0].type) {
        case "NUMBER":
          return param.numberValue;
        case "STRING":
          return param.stringValue;
        case "BOOLEAN":
          return param.booleanValue == true;
      }
    }
  }

  isFormValid() {
    const invalidElements = this.deviceOperationParamDto
      .filter(value1 => value1.deviceTypeOperationParam.type != "BOOLEAN")
      .filter(deviceTypeOperationParam => {
        switch (deviceTypeOperationParam.deviceTypeOperationParam.type) {
          case "NUMBER":
            return !(Number.isInteger(deviceTypeOperationParam.actualParam.numberValue) && deviceTypeOperationParam.actualParam.numberValue > 0);

          case "STRING":
            return !(deviceTypeOperationParam.actualParam.stringValue && deviceTypeOperationParam.actualParam.stringValue.trim() === "");
        }
      });
    this.isValidForm = invalidElements.length === 0;
  }

  onNumberValueChange(deviceOperationParam: DeviceOperationParamDto, $event: any) {
    deviceOperationParam.numberValue = $event.value;
    this.isFormValid();
  }

  resetInput() {
    this.deviceOperationParamDto.forEach(value => {
      switch (value.deviceTypeOperationParam.type) {
        case "BOOLEAN":
          value.actualParam.booleanValue = false;
          break;
        case "NUMBER":
          value.actualParam.numberValue = null;
          break;
        case "STRING":
          value.actualParam.stringValue = null;
          break;
      }
    })
  }

  showDeviceOperationParams() {
    this.showDeviceOperationParamsForm = true;
    this.resetInput();
  }
}
