<div class="flex flex-wrap align-content-start align-items-start">
  <ng-container *ngFor="let deviceKey of deviceDataKeys()">
    <ng-container *ngFor="let chartKey of chartDataKeys(deviceKey)">
      <app-sensor-data
        *ngIf="!isFavOnly || isFav(deviceKey, chartKey)"
        [ngClass]="'flex-item'"
        [favourite]="isFav(deviceKey,chartKey)"
        (toggleIsFavoriteNotification)="toggleIsFavoriteNotification(deviceKey,chartKey)"
        [dataType]="chartKey"
        [frequencies]="frequencies"
        [option]="chartOptions"
        [calibrationTypes]="calibrationTypes"
        [operationTypes]="operationTypes"
        [data]="deviceDataValues(deviceKey, chartKey)"
        [deviceData]="getDeviceData(deviceKey)"
        [isAlerted]="getAlert(deviceKey, chartKey)"
        [deviceId]="deviceKey">
      </app-sensor-data>
    </ng-container>
  </ng-container>
</div>


