import {InjectionToken} from '@angular/core';
import {
  ApiConfigurationParams as HydroponicsDeviceManagementApiConfigurationParams
} from 'src/generated/hydroponics-device-management-api/api-configuration';
import {
  ApiConfigurationParams as HydroponicsUserManagementApiConfigurationParams
} from 'src/generated/hydroponics-user-management-api/api-configuration';
import {BehaviorSubject} from "rxjs";

export interface Configuration {
  production: boolean;
  deviceManagementApiUrl: string;
  userManagementApiUrl: string;
  alertHours: number;
}

// We use a dependency injection token to access the configuration in our application.
export const configurationToken = new InjectionToken('Configuration');

const configJson=new BehaviorSubject(null);
export const configurationPath = '/configuration/configuration.json';
let keycloakUrl: string;

export function getHydroponicsDeviceManagementApiConfigurationParams(): HydroponicsDeviceManagementApiConfigurationParams {
  const config: HydroponicsDeviceManagementApiConfigurationParams = {};
  // configJson.pipe(skipWhile(val => !!val)).subscribe(configuration=>{
  //   config.rootUrl = configuration.deviceManagementApiUrl;
  // });
  // return config;
  fetch(configurationPath)
    .then(response => response.json())
    .then(configuration => {
      config.rootUrl = configuration.deviceManagementApiUrl;
    })
    .catch(error => {
      console.error('Cannot find configuration file: ' + configurationPath, error);
      throw error;
    });
  return config;
}
export function getHydroponicsUserManagementApiConfigurationParams(): HydroponicsUserManagementApiConfigurationParams {
  const config: HydroponicsUserManagementApiConfigurationParams = {};
  fetch(configurationPath)
    .then(response => response.json())
    .then(configuration => {
      config.rootUrl = configuration.userManagementApiUrl;
    })
    .catch(error => {
      console.error('Cannot find configuration file: ' + configurationPath, error);
      throw error;
    });
  return config;
}

export function getConfig() {
  let keycloakUrl: string;

  return fetch(configurationPath)
    .then(response => response.json())
    .then(configuration => {
      configJson.next(configuration);
      return configuration;
    })
    .catch(error => {
      console.error('Cannot find configuration file: ' + configurationPath, error);
      throw error;
    });

}

export function getConfigData(): Configuration {
  return configJson.getValue();
}
