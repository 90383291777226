import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../service/auth.service";

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.sass']
})
export class AuthCallbackComponent implements OnInit {

  constructor(private authService: AuthService) {}

  ngOnInit() {
    // Sikeres bejelentkezés után visszairányítás
    this.authService.handleSuccessfulLogin();
  }

}
