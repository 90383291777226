import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot,} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';

@Injectable()
export class AppAuthGuard extends KeycloakAuthGuard {


  constructor(
    // @ts-ignore
    protected router: Router,
    // @ts-ignore
    protected keycloakAngular: KeycloakService
  ) {
    super(router, keycloakAngular);
  }

  isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let permission = true;
      if (!this.authenticated) {
        console.log('Not authenticated, start login');
        this.keycloakAngular.login().catch((e) => console.error('Error while login with keycloak: ' + e));
        return reject(false);
      }

      const requiredRoles: string[] = route.data['roles'];
      if (!requiredRoles || requiredRoles.length === 0) {
        permission = true;
      } else {
        if (!this.roles || this.roles.length === 0) {
          permission = false
        }
        if (requiredRoles.every((role) => this.roles.indexOf(role) > -1))
        {
          permission=true;
        } else {
          permission=false;
        };
      }
      if(!permission){
        console.log("has not permission")
        // this.router.navigate(['/']);
      }
      resolve(permission)
    });
  }
}
